import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { map, of, take, tap } from 'rxjs';
import { Role } from '../../shared/enums/role';
import { LocalStorageService } from '../../shared/services/local-storage.service';

/**
 * Guarda de rota para acesso apenas a profissionais logados.
 *
 * @param route - Objeto de rota.
 * @param state - Estado da rota.
 * @returns Promise<boolean> - Retorna um observable que emite true se o usuário estiver logado, caso contrário, retorna false.
 */
export const RoleGuard: CanActivateFn = (route, state) => {
  // Injeta o serviço de autenticação e o roteador
  const authService = inject(AuthService);
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  // Recupera os papéis permitidos da rota
  const allowedRoles = route.data['roles'] as Array<Role>;

  return (localStorageService.getAuthToken()) ? (authService.loadUserJwt(localStorageService.getAuthToken() || '').pipe(
    take(1), // Garantir que pegamos apenas o primeiro valor emitido
    map(isLoggedIn => {
      if (isLoggedIn) {
        // Verifica se o usuário tem algum dos papéis permitidos
        if (authService.hasAnyRole(allowedRoles)) {
          return true;
        } else {
          // Se não tiver os papéis necessários, redireciona para uma página de acesso negado
          router.navigate(['/access-denied']);
          return false;
        }
      } else {
        // Se o usuário não estiver logado, redireciona para a página de login
        authService.logout();
        router.navigate(['/login']);
        return false;
      }
    })
  )) : of(false).pipe(
    tap(() => {
      // Se o usuário não estiver logado, redireciona para a página de login
      authService.logout();
      router.navigate(['/login']);
    })
  );
};
